import { isDefined } from "@clipboard-health/util-ts";

import { type Facility } from "../types";
import { useGetExtraTimePaySettings } from "./useGetExtraTimePaySettings";

type UseIsExtraTimePayEnabledProps = Pick<Facility, "userId">;

export function useIsExtraTimePayEnabled(facility?: UseIsExtraTimePayEnabledProps) {
  const { data: etpSettings } = useGetExtraTimePaySettings(facility?.userId);

  return isDefined(etpSettings?.workdayOptions) || isDefined(etpSettings?.weeklyOption);
}
