import { type UseModalState } from "@clipboard-health/ui-react";
import { Text } from "@clipboard-health/ui-react";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Drawer, IconButton, Stack } from "@mui/material";

import { ExtraTimePayCard } from "./ExtraTimePayCard";

interface ExtraTimePayBottomSheetProps {
  facilityUserId: string;
  modalState: UseModalState;
}

export function ExtraTimePayBottomSheet(props: ExtraTimePayBottomSheetProps) {
  const { facilityUserId, modalState } = props;
  const { modalIsOpen, closeModal } = modalState;

  return (
    <Drawer
      anchor="bottom"
      open={modalIsOpen}
      PaperProps={{
        sx: {
          borderRadius: 2,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },
      }}
      onClose={() => {
        closeModal();
      }}
    >
      <Stack sx={{ padding: 3, paddingBottom: 12 }} spacing={1}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Text bold variant="h4">
            Extra Time Pay
          </Text>
          <IconButton
            size="large"
            sx={{ color: "black", margin: "unset", padding: 0 }}
            onClick={(event) => {
              // Because of the way the app is structured, sometimes this component is used within
              // a card. If that card has a global click handler, clicking on this close button will
              // also trigger the click of the card. To prevent that, we stop the event propagation.
              event.stopPropagation();
              closeModal();
            }}
          >
            <CancelOutlinedIcon />
          </IconButton>
        </Stack>
        <ExtraTimePayCard variant="embedded" facilityUserId={facilityUserId} />
      </Stack>
    </Drawer>
  );
}
