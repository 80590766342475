import { type ApiResponse, get } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { useQueries, type UseQueryOptions } from "@tanstack/react-query";
import { z } from "zod";

export const getFacilityExclusionParamsSchema = z.object({
  facilityId: z.string(),
  agentId: z.string().optional(),
  pageSize: z.number().optional(),
});

export type GetFacilityExclusionParams = z.infer<typeof getFacilityExclusionParamsSchema>;

export const facilityExclusionSchema = z.object({
  _id: z.string(),
  agent: z.object({
    userId: z.string(),
    name: z.string(),
  }),
  facility: z.object({
    _id: z.string(),
    name: z.string(),
    email: z.string(),
    userId: z.string(),
  }),
  reason: z.string(),
  notes: z.string(),
  actionBy: z.enum(["AGENT", "FACILITY"]),
  createdAt: z.string(),
});

export const getFacilityExclusionResponseSchema = z.array(facilityExclusionSchema);

export type FacilityExclusionApiResponse = z.infer<typeof getFacilityExclusionResponseSchema>;

export const getFacilitiesExclusionParamsSchema = z.object({
  facilityIds: z.array(z.string()),
  agentId: z.string(),
});

export type GetFacilitiesExclusionParams = z.infer<typeof getFacilitiesExclusionParamsSchema>;
export function useGetFacilitiesExclusions(
  params: GetFacilitiesExclusionParams,
  options?: UseQueryOptions<ApiResponse<FacilityExclusionApiResponse>>
) {
  const { facilityIds, agentId } = params;

  return useQueries({
    queries: facilityIds.map((facilityId) => ({
      queryKey: ["facilityExclusions", facilityId],
      queryFn: async () => {
        return await get({
          url: `${environmentConfig.REACT_APP_BASE_API_URL}/exclusion`,
          queryParams: {
            facilityId,
            agentId,
          },
          responseSchema: getFacilityExclusionResponseSchema,
        });
      },
      meta: {
        logErrorMessage: APP_V2_APP_EVENTS.GET_FACILITY_EXCLUSION_FAILURE,
      },
      ...options,
    })),
  });
}
