import { isDefined } from "@clipboard-health/util-ts";
import { get } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { useQuery, type UseQueryOptions, type UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";

export const extraTimePayOptionsSchema = z.object({
  threshold: z.number(),
  multiplier: z.number(),
});

export type ExtraTimePayOptions = z.infer<typeof extraTimePayOptionsSchema>;

export const extraTimePaySettingsSchema = z.object({
  _id: z.string(),
  facilityId: z.string(),
  workdayOptions: z.array(extraTimePayOptionsSchema).nullable(),
  weeklyOption: extraTimePayOptionsSchema.nullable(),
});

export type ExtraTimePaySettings = z.infer<typeof extraTimePaySettingsSchema>;

export const getExtraTimePaySettingsResponseSchema = z.object({
  data: extraTimePaySettingsSchema,
});

export type GetExtraTimePaySettingsResponseSchema = z.infer<
  typeof getExtraTimePaySettingsResponseSchema
>;

export function getExtraTimePaySettingsUrl(facilityId: string): string {
  return `${environmentConfig.REACT_APP_BASE_API_URL}/facilities/${facilityId}/extra-time-pay-settings`;
}

export function useGetExtraTimePaySettings(
  facilityId?: string,
  options: UseQueryOptions<ExtraTimePaySettings | undefined> = {}
): UseQueryResult<ExtraTimePaySettings | undefined> {
  return useQuery({
    queryKey: [getExtraTimePaySettingsUrl(facilityId ?? "")],
    queryFn: async () => {
      const response = await get({
        url: getExtraTimePaySettingsUrl(facilityId ?? ""),
        responseSchema: getExtraTimePaySettingsResponseSchema,
      });
      return response.data.data;
    },
    enabled: isDefined(facilityId),
    staleTime: 5000,
    // 404 errors are allowed here. If we don't set this to false, they're catched
    // by the global error boundary.
    // FIXME: https://linear.app/clipboardhealth/issue/FEF-579/add-allowederrorstatuscodes-to-the-api
    useErrorBoundary: false,
    ...options,
  });
}
