import { Text } from "@clipboard-health/ui-react";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { Box, Stack } from "@mui/material";
import { type ReactNode } from "react";

interface ExtraTimePaySectionProps {
  isEnabled?: boolean;
  title: string;
  children: ReactNode;
}

export function ExtraTimePaySection(props: ExtraTimePaySectionProps) {
  const { isEnabled = false, title, children } = props;

  return (
    <Stack direction="row" spacing={0.5} alignItems="top">
      <Box flexShrink={0}>
        {isEnabled ? (
          <CheckCircleOutlineOutlinedIcon sx={{ color: (theme) => theme.palette.primary.main }} />
        ) : (
          <CancelOutlinedIcon sx={{ color: (theme) => theme.palette.action.disabled }} />
        )}
      </Box>
      <Stack spacing={0.5}>
        <Text variant="caption" sx={{ fontWeight: 600 }}>
          {title}
        </Text>
        <Stack spacing={1}>{children}</Stack>
      </Stack>
    </Stack>
  );
}
