import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  type IconButtonProps,
  Stack,
  useTheme,
} from "@mui/material";
import throttle from "lodash/throttle";
import { type ReactNode, useState } from "react";

interface ExpandMoreProps extends IconButtonProps {
  isExpanded: boolean;
}

function ExpandMore(props: ExpandMoreProps) {
  const { isExpanded, ...restProps } = props;
  return (
    <IconButton
      sx={{
        transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
        marginLeft: "auto",
        transition: (theme) =>
          theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
          }),
      }}
      {...restProps}
    />
  );
}

export interface FacilityDetailsCollapseProps {
  icon: ReactNode;
  title: string;
  children: ReactNode;
  isExpandedByDefault?: boolean;
  disableToggle?: boolean;
  onToggle?: (isExpanded: boolean) => void;
}

export function FacilityDetailsCollapse({
  icon,
  title,
  children,
  onToggle,
  isExpandedByDefault = false,
  disableToggle = false,
}: FacilityDetailsCollapseProps) {
  const theme = useTheme();

  const [isExpanded, setIsExpanded] = useState(isExpandedByDefault);

  const handleExpandClick = throttle(() => {
    setIsExpanded(!isExpanded);
    onToggle?.(!isExpanded);
  }, 300);

  return (
    <Stack sx={{ margin: 4 }}>
      <Card
        elevation={1}
        sx={{
          borderRadius: 3,
          backgroundColor: theme.palette.grey[200],
        }}
      >
        <CardHeader
          sx={{
            paddingX: 4,
            backgroundColor: theme.palette.common.white,
            cursor: disableToggle ? "default" : "pointer",
            userSelect: "none",
          }}
          titleTypographyProps={{ variant: "body1" }}
          avatar={icon}
          title={title}
          action={
            !disableToggle && (
              <ExpandMore
                isExpanded={isExpanded}
                aria-expanded={isExpanded}
                aria-label="show more"
                onClick={handleExpandClick}
              >
                <ExpandMoreIcon />
              </ExpandMore>
            )
          }
          onClick={disableToggle ? undefined : handleExpandClick}
        />
        <Collapse unmountOnExit in={isExpanded} timeout="auto">
          <CardContent
            sx={{
              paddingTop: 1,
              paddingBottom: 1,
              paddingLeft: 4,
            }}
          >
            {children}
          </CardContent>
        </Collapse>
      </Card>
    </Stack>
  );
}
