import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { type UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";

import { facilityNoteSchema } from "../types";

function getFacilityNotesUrl(workplaceId: string): string {
  return `${environmentConfig.REACT_APP_BASE_API_URL}/facilityNoteCategory/get-notes/${workplaceId}`;
}

const facilityNotesResponseSchema = z.array(facilityNoteSchema);

export type FacilityNotesResponse = z.infer<typeof facilityNotesResponseSchema>;

export function useFacilityNotes(
  workplaceId: string,
  options: UseGetQueryOptions<FacilityNotesResponse> = {}
): UseQueryResult<FacilityNotesResponse> {
  return useGetQuery({
    url: `${getFacilityNotesUrl(workplaceId)}`,
    responseSchema: facilityNotesResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_FACILITY_NOTES_FAILURE,
    },
    ...options,
  });
}
