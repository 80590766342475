import { isNullOrUndefined } from "@clipboard-health/util-ts";
import { type LegacyGeoLocationCoordinatesAndType } from "@lib/interface/src/index";
import { convertToGeoLocation } from "@src/appV2/Location/index";
import { calculateEstimatedDrivingDistanceInMiles } from "@src/appV2/Location/utils/geoDistance";
import { type Worker } from "@src/appV2/Worker/api/types";

import { type Facility } from "../types";

export function calculateDistanceToFacilityInMiles(
  agent?:
    | Partial<Pick<Worker, "geoLocation">>
    | { geoLocation?: LegacyGeoLocationCoordinatesAndType },
  facility?: Partial<Pick<Facility, "geoLocation">>
): number {
  const agentCoordinates = agent?.geoLocation?.coordinates;
  const facilityCoordinates = facility?.geoLocation?.coordinates;

  if (isNullOrUndefined(agentCoordinates) || isNullOrUndefined(facilityCoordinates)) {
    return -1;
  }

  const agentGeoLocation = convertToGeoLocation(agentCoordinates);
  const facilityGeoLocation = convertToGeoLocation(facilityCoordinates);

  const distance = calculateEstimatedDrivingDistanceInMiles(agentGeoLocation, facilityGeoLocation);

  return Math.round(2 * distance) / 2;
}
