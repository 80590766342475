import { formatDate } from "@clipboard-health/date-time";
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, Stack } from "@mui/material";
import { useGetExtraTimePaySettings } from "@src/appV2/Facilities/api/useGetExtraTimePaySettings";
import { useToast } from "@src/appV2/lib";

import { ExtraTimePayLoader } from "./ExtraTimePayLoader";
import { ExtraTimePaySection } from "./ExtraTimePaySection";

interface ExtraTimePayCardProps {
  variant?: "embedded" | "standalone";
  facilityUserId: string;
}

export function ExtraTimePayCard(props: ExtraTimePayCardProps) {
  const { facilityUserId, variant = "standalone" } = props;

  const { showErrorToast } = useToast();

  const {
    isLoading: isLoadingEtpSettings,
    data: etpSettings,
    isError,
  } = useGetExtraTimePaySettings(facilityUserId, {
    onError: () => {
      showErrorToast("Something went wrong while loading extra time pay settings");
    },
  });

  if (isError || isLoadingEtpSettings || !isDefined(etpSettings)) {
    return <ExtraTimePayLoader />;
  }

  const { workdayOptions, weeklyOption } = etpSettings;

  const isWorkdayOptionEnabled = isDefined(workdayOptions);
  const isWeeklyOptionEnabled = isDefined(weeklyOption);

  const isSomeOptionEnabled = isWorkdayOptionEnabled || isWeeklyOptionEnabled;
  const isSomeOptionDisabled = !isWorkdayOptionEnabled || !isWeeklyOptionEnabled;
  const isStandalone = variant === "standalone";

  return (
    <Stack spacing={2}>
      {isStandalone && <Text variant="caption">As of {formatDate(new Date())}</Text>}
      <Stack spacing={2}>
        {isSomeOptionEnabled ? (
          <Stack spacing={1}>
            <Text bold variant="caption">
              Types of Extra Time Pay offered
            </Text>
            {isWorkdayOptionEnabled ? (
              <ExtraTimePaySection isEnabled title="Workday">
                {workdayOptions.map((option) => (
                  <Box key={`${option.multiplier}-${option.threshold}`}>
                    <Text variant="caption">
                      {option.multiplier}x the pay rate for every consecutive hour worked over{" "}
                      {option.threshold} hours
                    </Text>
                  </Box>
                ))}
              </ExtraTimePaySection>
            ) : null}
            {isWeeklyOptionEnabled ? (
              <ExtraTimePaySection isEnabled title="Weekly">
                <Text variant="caption">
                  {weeklyOption.multiplier}x the pay rate for every hour worked over{" "}
                  {weeklyOption.threshold} hours in a calendar week at this facility from Sunday to
                  Saturday
                  {isWorkdayOptionEnabled ? ", if not already earned via a Workday bonus" : ""}
                </Text>
              </ExtraTimePaySection>
            ) : null}
          </Stack>
        ) : null}
        {isSomeOptionDisabled ? (
          <Stack spacing={1}>
            <Text bold variant="caption">
              Types of Extra Time Pay not offered
            </Text>
            {!isWorkdayOptionEnabled && (
              <ExtraTimePaySection title="Workday">
                <Text variant="caption">
                  This facility does not offer Extra Time Pay for working consecutive hours on a
                  single or back-to-back shifts for any duration of time
                </Text>
              </ExtraTimePaySection>
            )}
            {!isWeeklyOptionEnabled && (
              <ExtraTimePaySection title="Weekly">
                <Text variant="caption">
                  This facility does not offer Extra Time Pay for working more than 40 hours in a
                  calendar week
                </Text>
              </ExtraTimePaySection>
            )}
          </Stack>
        ) : null}
      </Stack>
    </Stack>
  );
}
