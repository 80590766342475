import { post, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics";
import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import {
  type FacilityVerificationPreferences,
  facilityVerificationPreferencesResponseSchema,
} from "../types";

const facilityVerificationPreferencesRequestSchema = z.object({
  facilityIds: z.array(z.string()),
});
type FacilityVerificationPreferencesParams = z.infer<
  typeof facilityVerificationPreferencesRequestSchema
>;

export function useFacilityVerificationPreferences(
  queryParams: FacilityVerificationPreferencesParams,
  options: UseGetQueryOptions<FacilityVerificationPreferences[]> = {}
) {
  const url = `${environmentConfig.REACT_APP_BASE_API_URL}/facilityprofile/verificationPreferences`;

  return useQuery({
    queryKey: [url, queryParams],
    queryFn: async () => {
      // the endpoint is a POST, but it only reads data and returns it,
      // so we manage its state via `useQuery`, and don't treat it as a mutation
      const response = await post({
        url,
        data: queryParams,
        requestSchema: facilityVerificationPreferencesRequestSchema,
        responseSchema: facilityVerificationPreferencesResponseSchema,
      });

      return response.data.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_FACILITY_VERIFICATION_PREFERENCES_FAILURE,
    },
    ...options,
  });
}
