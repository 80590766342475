import { isDefined } from "@clipboard-health/util-ts";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { type Shift } from "@src/appV2/Shifts/Shift/types";

export function useGeolocationTrackingForShiftsEnabled(shift: Shift): boolean {
  const geolocationTrackingForShiftsEnabledMap = useCbhFlag(
    CbhFeatureFlag.ENABLE_GEOLOCATION_TRACKING_FOR_SHIFTS,
    { defaultValue: {} }
  );

  return isDefined(shift.facility?.type)
    ? geolocationTrackingForShiftsEnabledMap[shift.facility.type] ?? true
    : true;
}
