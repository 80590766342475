import { Image } from "@clipboard-health/ui-react";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { Box, IconButton, MobileStepper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import SwipeableViews from "react-swipeable-views";

export interface CarouselImage {
  url: string;
  label: string;
}

interface FacilityImageCarouselProps {
  images: CarouselImage[];
}

export function FacilityImageCarousel({ images }: FacilityImageCarouselProps) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((previousActiveStep) => previousActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((previousActiveStep) => previousActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ flexGrow: 1, height: "70%" }} position="relative">
      <SwipeableViews
        enableMouseEvents
        axis="x"
        index={activeStep}
        onChangeIndex={handleStepChange}
      >
        {images.map((step, index) => (
          <Box key={step.url}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Image
                sx={{
                  display: "block",
                  aspectRatio: "4/3",
                  overflow: "hidden",
                  width: "100%",
                  objectFit: "contain",
                }}
                src={step.url}
                alt={step.label}
              />
            ) : null}
          </Box>
        ))}
      </SwipeableViews>

      {maxSteps > 1 && (
        <MobileStepper
          steps={maxSteps}
          activeStep={activeStep}
          position="static"
          sx={{
            background: "white",
            borderRadius: theme.shape.borderRadius,
            bottom: theme.spacing(0.5),
            left: "50%",
            minWidth: "30%",
            padding: 0,
            position: "absolute",
            right: 0,
            transform: "translateX(-50%)",
            width: "fit-content",
            ".MuiMobileStepper-dots": {
              padding: theme.spacing(1),
            },
          }}
          nextButton={
            <IconButton
              size="small"
              disabled={activeStep === maxSteps - 1}
              aria-label="Next image"
              onClick={handleNext}
            >
              <KeyboardArrowRight />
            </IconButton>
          }
          backButton={
            <IconButton
              size="small"
              disabled={activeStep === 0}
              aria-label="Previous image"
              onClick={handleBack}
            >
              <KeyboardArrowLeft />
            </IconButton>
          }
        />
      )}
    </Box>
  );
}
